import React from 'react';

const Pagination = ({ currentPage, itemsPerPage, totalItems, paginate, setItemsPerPage }) => {
  const pageNumbers = [];
  
  // Calculer le nombre total de pages
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  // Fonction pour changer le nombre d'éléments par page
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));  // Met à jour le nombre d'éléments par page
  };

  // Vérifier si aucun PDF n'est trouvé
  const noItemsFound = totalItems === 0;

  return (
    <div className="pagination-container flex justify-between items-center mt-6">
      {/* Sélecteur de nombre d'éléments par page */}
      <div>
        <label htmlFor="itemsPerPage" className="mr-2">Affichage par page :</label>
        <select
          id="itemsPerPage"
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className="p-2 border border-gray-300 rounded-md"
        >
          <option value={30}>30</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>

      {/* Pagination */}
      <div>
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1 || noItemsFound}  // Désactive si on est à la première page ou s'il n'y a pas de fichiers
          className={`px-4 py-2 mx-1 bg-gray-200 rounded-md ${noItemsFound ? 'disabled:opacity-50' : ''}`}
        >
          Précédent
        </button>
        {pageNumbers.map(number => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`px-4 py-2 mx-1 rounded-md ${currentPage === number ? 'bg-teal-400 text-white' : 'bg-gray-200'}`}
          >
            {number}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === pageNumbers.length || noItemsFound}  // Désactive si on est à la dernière page ou s'il n'y a pas de fichiers
          className={`px-4 py-2 mx-1 bg-gray-200 rounded-md ${noItemsFound ? 'disabled:opacity-50' : ''}`}
        >
          Suivant
        </button>
      </div>
    </div>
  );
};

export default Pagination;