import { useState, useEffect, useCallback } from 'react';
import axios from "axios";
import Navbar from './Navbar';
import { usePdfContext } from '../context/PdfContext';
import { useNavigate } from 'react-router-dom';

function Home() {
  const [pdfArrayBuffer, setPdfArrayBuffer] = useState(null);
  const [title, setTitle] = useState("");
  const [file, setFile] = useState("");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [editingCategoryId, setEditingCategoryId] = useState(null); // ID de la catégorie en cours de modification
  const [categoryNameToUpdate, setCategoryNameToUpdate] = useState(''); // Nom de la catégorie en cours de modification
  const { allPdfs, setAllPdfs } = usePdfContext();
  const [profileImage, setProfileImage] = useState("");
  const [profileImageFile, setProfileImageFile] = useState(null);
  const navigate = useNavigate();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const token = localStorage.getItem("token");
  const [userId, setUserId] = useState(""); // Ajout de l'état pour stocker l'ID de l'utilisateur
  const [mairieId, setMairieId] = useState("");
  const fullUrl = window.location.origin; // Récuperer le nom de domaine
  const [errors, setErrors] = useState({}); // stockage des erreurs pour le form pdf
  const [pdfList, setPdfList] = useState([]); // mettre à jour les pdfs dans letat

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL+"/api",
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
    },
  });

  useEffect(() => {
    if (!token) {
      alert("Vous devez être connecté pour accéder à cette page.");
      navigate('/login');
    }
  }, [token, navigate]);

  const getPdf = useCallback(async () => {
    if (!token) return;

    try {
      console.log("Fetching PDFs...");
      const result = await authAxios.get("/get-files");
      console.log("PDFs fetched:", result.data.data);
      setAllPdfs(result.data.data);
    } catch (error) {
      console.error("Error fetching PDF files:", error.message || error);
    }
  }, [setAllPdfs, authAxios, token]);

  const getCategories = useCallback(async () => {
    if (!token) return;

    try {
      console.log("Fetching categories...");
      const result = await authAxios.get("/get-categories");
      console.log("Categories fetched:", result.data.categories);

      // Trier les catégories par ordre alphabétique
      const sortedCategories = result.data.categories.sort((a, b) => {
        return a.name.localeCompare(b.name); // Trie par le nom de la catégorie (ordre alphabétique)
      });

      setCategories(sortedCategories);
    } catch (error) {
      console.error("Error fetching categories:", error.message || error);
    }
  }, [authAxios, token]);


  useEffect(() => {
    if (token && !isDataLoaded) {
      getCategories();
      getPdf();
      setIsDataLoaded(true); // Marquer les données comme chargées après la première exécution
    }
  }, [getCategories, getPdf, token, isDataLoaded]);

  const handleAddCategory = async () => {
    if (!newCategory) {
      console.error("Le champs catégorie est vide");
      return;
    }
    if (!token) return;

    try {
      const result = await authAxios.post("/categories", { name: newCategory });

      // Ajout de la nouvelle catégorie et tri de la liste
      const updatedCategories = [...categories, result.data.category];
      updatedCategories.sort((a, b) => a.name.localeCompare(b.name)); // Tri alphabétique
      setCategories(updatedCategories);

      setNewCategory('');
    } catch (error) {
      console.error("Erreur lors de l'ajout de la catégorie:", error.message || error);
    }
  };

  const handleUpdateCategory = async () => {
    if (!categoryNameToUpdate || !token || !editingCategoryId) return;

    try {
      const result = await authAxios.put(`/categories/${editingCategoryId}`, { name: categoryNameToUpdate });
      setCategories(categories.map(category =>
        category._id === editingCategoryId ? result.data.category : category
      ));
      setEditingCategoryId(null);
      setCategoryNameToUpdate('');
    } catch (error) {
      console.error("Error updating category:", error.message || error);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    if (!token) return;

    try {
      // Vérification des PDFs associés à la catégorie
      const pdfsLinkedToCategory = allPdfs.filter(pdf => pdf.category === categoryId);
      if (pdfsLinkedToCategory.length > 0) {
        // Affichage d'un message d'erreur si des PDFs sont liés à la catégorie
        alert(`Impossible de supprimer cette catégorie car elle est actuellement reliée à ${pdfsLinkedToCategory.length} PDF(s).`);
        return;
      }

      // Suppression de la catégorie si aucun PDF n'est lié
      await authAxios.delete(`/categories/${categoryId}`);
      setCategories(categories.filter(category => category._id !== categoryId));
    } catch (error) {
      console.error("Error deleting category:", error.message || error);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  async function onSubmit(e, redirectToPdfPage = false) {
    e.preventDefault();

    if (!token) {
      alert("Vous devez être connecté pour télécharger un fichier.");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("file", file);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("category", selectedCategory);
    formData.append("mairieId", mairieId);

    try {
        const result = await authAxios.post("/upload-files", formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });

        if (result.data.status === "ok") {
            alert("PDF ajouté avec succès");
            setTitle("");
            setStartDate('');
            setEndDate('');
            setSelectedCategory('');

            // Récupérer la liste mise à jour et la mettre à jour localement
            const updatedPdfs = await getPdf(); // Assurez-vous que getPdf renvoie la liste actualisée

            // Mettre à jour la liste dans le composant ou le contexte
            setPdfList(updatedPdfs);

            if (redirectToPdfPage) {
                navigate("/uploadedfiles");
            }
        } else {
            alert("Echec de l'upload");
        }
    } catch (error) {
      console.error("Error uploading the file:", error.message || error);
      alert("An error occurred while uploading the file.");
    }
  }

useEffect(() => {
    getPdf().then(setPdfList); // Charger les PDF au montage
}, []);


  useEffect(() => {
    // Récupérez l'image de profil de l'utilisateur
    const fetchUserProfileImage = async () => {
      try {
        const result = await authAxios.get("/get-user-profile"); // Assurez-vous que cette route retourne le profil utilisateur
        console.log("User profile data:", result.data);
        setProfileImage(result.data.profileImage);
        setUserId(result.data.userId);
        setMairieId(result.data.mairieId);
      } catch (error) {
        console.error("Error fetching profile image:", error.message || error);
      }
    };

    fetchUserProfileImage();
  }, [authAxios]);

  const handleProfileImageChange = (e) => {
    setProfileImageFile(e.target.files[0]);
  };

  const handleProfileImageUpload = async () => {
    if (!profileImageFile) return;

    const formData = new FormData();
    formData.append("profileImage", profileImageFile);

    try {
      const result = await authAxios.post("/upload-profile-image", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setProfileImage(result.data.profileImage);
    } catch (error) {
      console.error("Error uploading profile image:", error.message || error);
    }
  };

  const handleProfileImageDelete = async () => {
    try {
      await authAxios.delete("/delete-profile-image");
      setProfileImage(""); // Supprime l'image de l'état local
    } catch (error) {
      console.error("Error deleting profile image:", error.message || error);
    }
  };



  return (
    <>
      <Navbar />
      <div className="mt-5 flex justify-center space-x-8">
        {/* Formulaire d'ajout de PDF à gauche */}
        <div className="p-8 bg-white border shadow-md rounded-md w-full max-w-2xl">
          <h1 className="text-2xl font-bold">Gestion des PDF</h1>
          <form onSubmit={(e) => onSubmit(e, false)} className="mt-6">
            <input
              type="text"
              placeholder="Titre"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className="w-full border border-gray-300 rounded-md p-2 mb-4"
            />
            {errors.title && (
              <div className="mt-2 text-sm text-red-600 flex items-center space-x-1">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10a1 1 0 10-2 0v4a1 1 0 002 0V8zm0 6a1 1 0 10-2 0v2a1 1 0 002 0v-2z" clipRule="evenodd" />
                </svg>
                <span>{errors.title}</span>
              </div>
            )}
            <input
              type="file"
              accept="application/pdf"
              onChange={(e) => setFile(e.target.files[0])}
              required
              className="w-full border border-gray-300 rounded-md p-2 mb-4"
            />
            {errors.file && (
              <div className="mt-2 text-sm text-red-600 flex items-center space-x-1">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10a1 1 0 10-2 0v4a1 1 0 002 0V8zm0 6a1 1 0 10-2 0v2a1 1 0 002 0v-2z" clipRule="evenodd" />
                </svg>
                <span>{errors.file}</span>
              </div>
            )}
            <div className="mb-4">
              <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">Date de début</label>
              <input
                id="startDate"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
                className="w-full border border-gray-300 rounded-md p-2 mb-4"
              />
              {errors.startDate && (
                <div className="mt-2 text-sm text-red-600 flex items-center space-x-1">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10a1 1 0 10-2 0v4a1 1 0 002 0V8zm0 6a1 1 0 10-2 0v2a1 1 0 002 0v-2z" clipRule="evenodd" />
                  </svg>
                  <span>{errors.startDate}</span>
                </div>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">Date de fin</label>
              <input
                id="endDate"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
                className="w-full border border-gray-300 rounded-md p-2 mb-4"
              />
              {errors.endDate && (
                <div className="mt-2 text-sm text-red-600 flex items-center space-x-1">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10a1 1 0 10-2 0v4a1 1 0 002 0V8zm0 6a1 1 0 10-2 0v2a1 1 0 002 0v-2z" clipRule="evenodd" />
                  </svg>
                  <span>{errors.endDate}</span>
                </div>
              )}
            </div>

            <div className="mb-4">
              <select
                value={selectedCategory}
                onChange={handleCategoryChange}
                className="w-full border border-gray-300 rounded-md p-2 mb-4"
              >
                <option value="">Selectionner une catégorie</option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>{category.name}</option>
                ))}
              </select>
              {errors.category && (
                  <div className="mt-2 text-sm text-red-600 flex items-center space-x-1">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10a1 1 0 10-2 0v4a1 1 0 002 0V8zm0 6a1 1 0 10-2 0v2a1 1 0 002 0v-2z" clipRule="evenodd" />
                    </svg>
                    <span>{errors.category}</span>
                  </div>
                )}
            </div>

            <button type="submit" className="w-full bg-blue-600 text-white p-2 rounded-md mb-4">
              Enregistrer et ajouter un nouveau PDF
            </button>
            <button
              type="button"
              className="w-full bg-green-600 text-white p-2 rounded-md mb-4"
              onClick={(e) => onSubmit(e, true)}
            >
              Enregistrer et voir mes PDF
            </button>
          </form>
        </div>

        {/* Bloc de gestion des catégories à droite */}
        <div className="p-8 bg-white border shadow-md rounded-md w-full max-w-xs">
          <h2 className="text-xl font-semibold mb-4">Gestion des catégories</h2>
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Nouvelle catégorie"
            className="w-full border border-gray-300 rounded-md p-2 mb-4"
          />
          <button
            onClick={handleAddCategory}
            className="w-full bg-green-600 text-white p-2 rounded-md mb-4"
          >
            Ajouter une catégorie
          </button>

          <div className="mt-5">
            <h2 className="font-bold text-lg">Mes catégories</h2>
            {categories.map((category) => (
              <div key={category._id} className="flex justify-between mt-2">
                <span>{editingCategoryId === category._id ? (
                  <input
                    type="text"
                    value={categoryNameToUpdate}
                    onChange={(e) => setCategoryNameToUpdate(e.target.value)}
                    className="w-3/4 border border-gray-300 rounded-md p-2"
                  />
                ) : category.name}</span>
                <div className="flex space-x-2">
                  {editingCategoryId === category._id ? (
                    <button
                      onClick={handleUpdateCategory}
                      className="text-yellow-600"
                    >
                      Mettre à jour
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setEditingCategoryId(category._id);
                        setCategoryNameToUpdate(category.name);
                      }}
                      className="text-blue-600"
                    >
                      Modifier
                    </button>
                  )}
                  <button
                    onClick={() => handleDeleteCategory(category._id)}
                    className="text-red-600"
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Bloc de gestion du logo à droite */}
  <div className="p-8 bg-white border shadow-md rounded-md w-full max-w-xs">
  <h2 className="text-xl font-semibold mb-4">Mon logo</h2>

  <input
    type="file"
    accept="image/*"
    onChange={handleProfileImageChange}
    className="w-full border border-gray-300 rounded-md p-2 mb-4"
  />
  <button
    onClick={handleProfileImageUpload}
    className="w-full bg-blue-600 text-white p-2 rounded-md mb-4"
  >
    Enregistrer mon logo
  </button>

  {profileImage && (
    <div className="text-center">
      <img
        src={process.env.REACT_APP_API_URL+`${profileImage}`}
        alt="Profil"
        className="w-[100px] h-auto object-cover mx-auto mb-4"
      />
      <button
        onClick={handleProfileImageDelete}
        className="text-red-600"
      >
        Supprimer mon logo
      </button>
    </div>
  )}
{userId && (       /* Vérification que userId n'est pas vide puis affiche l' user id à la fin de l'url du domaine */
         <a
         target="_blank"
         href={`${fullUrl}/${userId}`}
         className="inline-block bg-blue-600 text-white text-md font-semibold py-2 px-4 rounded-md shadow-md hover:bg-blue-700 transition-colors"
         >
          Mon URL
         </a>
  )}
</div>

      </div>
    </>
  );
}

export default Home;
