import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { usePdfContext } from '../context/PdfContext';
import Pagination from './Pagination';
import axios from 'axios';

function UploadedFiles() {
  const { allPdfs, fetchPdfs } = usePdfContext();
  const [pdfs, setPdfs] = useState(allPdfs || []);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedDate, setSelectedDate] = useState(''); // Un champ unique de date
  const token = localStorage.getItem('token');
  /// PAGINATION ///
  const [currentPage, setCurrentPage] = useState(1);  // Page actuelle
  const [itemsPerPage, setItemsPerPage] = useState(30);  // Nombre d'éléments par page
  // Calculer les indices des PDFs à afficher
  const indexOfLastPdf = currentPage * itemsPerPage;
  const indexOfFirstPdf = indexOfLastPdf - itemsPerPage;
  const currentPdfs = pdfs.slice(indexOfFirstPdf, indexOfLastPdf);

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL+'/api',
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
    },
  });

  useEffect(() => {
    const loadPdfs = async () => {
      console.log('Chargement des PDFs via fetchPdfs...');
      await fetchPdfs();
    };
    loadPdfs();
  }, []); // Appelé seulement au montage

  // Récupérer les catégories depuis le serveur
  const getCategories = async () => {
    try {
      const result = await authAxios.get('/get-categories');
      const sortedCategories = result.data.categories.sort((a, b) => a.name.localeCompare(b.name));
      setCategories(sortedCategories); // Mettre à jour l'état avec les catégories triées
    } catch (error) {
      console.error('Erreur lors de la récupération des catégories', error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

// Mettre à jour un PDF avec les nouvelles informations
const updatePdf = async (pdfId, updatedData, newFile = null) => {
  try {
    const formData = new FormData();
    formData.append('title', updatedData.title);
    formData.append('startDate', updatedData.startDate ? new Date(updatedData.startDate).toISOString() : "");
    formData.append('endDate', updatedData.endDate ? new Date(updatedData.endDate).toISOString() : "");
    formData.append('category', updatedData.category);

    if (newFile) {
      formData.append('file', newFile); // Ajout du fichier seulement s'il est présent
    }

    const result = await authAxios.put(`/update-pdf/${pdfId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (result.data.status === 'ok') {
      alert('PDF mis à jour avec succès!');

      // Fermer automatiquement le dropdown en mettant `isOpen` à false
      setPdfs(prevPdfs =>
        prevPdfs.map(pdf =>
          pdf._id === pdfId ? { ...pdf, isOpen: !pdf.isOpen } : pdf
        )
      );
    } else {
      alert('Échec de la mise à jour du PDF.');
    }
  } catch (error) {
    console.error('Erreur lors de la mise à jour du PDF', error);
    alert('Une erreur est survenue lors de la mise à jour.');
  }
};

  // Supprimer un PDF
  const deletePdf = async (pdfId) => {
    const confirmation = window.confirm('Êtes-vous sûr de vouloir supprimer ce PDF et toutes ses données ?');
    if (!confirmation) return;

    try {
      const result = await authAxios.delete(`/delete-pdf/${pdfId}`);
      if (result.data.status === 'ok') {
        alert('PDF supprimé avec succès!');
        setPdfs(pdfs.filter(pdf => pdf._id !== pdfId));
      } else {
        alert('Échec de la suppression du PDF.');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression du PDF', error);
      alert('Une erreur est survenue lors de la suppression.');
    }
  };

  // Gérer la modification des champs (titre, dates, catégorie, fichier PDF)
  const handleChange = (e, pdfId, field) => {
    const value = e.target.value;

    const updatedPdfs = pdfs.map(pdf => {
    if (pdf._id === pdfId) {
      const updatedPdf = { ...pdf, [field]: value };

      if (field === 'endDate' && new Date(updatedPdf.startDate) > new Date(value)) {
        alert("La date de fin ne peut pas être antérieure à la date de début.");
        return pdf; // Ne pas appliquer la modification si la validation échoue
      }

      if (field === 'startDate' && updatedPdf.endDate && new Date(value) > new Date(updatedPdf.endDate)) {
        alert("La date de début ne peut pas être postérieure à la date de fin.");
        return pdf; // Ne pas appliquer la modification si la validation échoue
      }

      return updatedPdf;
    }

    return pdf;
  });

    setPdfs(updatedPdfs);
  };

  const handleFileChange = (e, pdfId) => {
    const file = e.target.files[0];
    if (file) {
      const updatedPdfs = pdfs.map(pdf =>
        pdf._id === pdfId ? { ...pdf, pdfFile: file } : pdf
      );
      setPdfs(updatedPdfs);
    }
  };

  // Filtrer les PDFs en fonction de la recherche et des filtres
  const filterPdfs = () => {
    let filteredPdfs = [...allPdfs]; // Copie défensive pour éviter de muter l'état d'origine

    // Filtrer par recherche (par titre ou nom de fichier)
    if (searchQuery) {
      filteredPdfs = filteredPdfs.filter(pdf =>
        pdf.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        pdf.pdf.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Filtrer par catégorie
    if (selectedCategory) {
      filteredPdfs = filteredPdfs.filter(pdf => pdf.category === selectedCategory);
    }

    // Filtrer par date unique (si la date sélectionnée se trouve entre la date de début et de fin)
    if (selectedDate) {
      const selected = new Date(selectedDate);
      filteredPdfs = filteredPdfs.filter(pdf => {
        const startDate = new Date(pdf.startDate);
        const endDate = new Date(pdf.endDate);
        return selected >= startDate && selected <= endDate;
      });
    }

    setPdfs(filteredPdfs); // Met à jour les PDFs triés dans l'état local
  };

  // Fonction pour gérer l'ouverture/fermeture du dropdown
  const toggleDropdown = (pdfId) => {
    setPdfs(pdfs.map(pdf =>
      pdf._id === pdfId ? { ...pdf, isOpen: !pdf.isOpen } : pdf
    ));
  };

  const showPdf = (pdf) => {
    window.open(process.env.REACT_APP_API_URL+`/assets/pdf/${pdf}`, "_blank", "noreferrer");
  };

  // Mettre à jour les PDFs filtrés chaque fois qu'un critère change
  useEffect(() => {
    // Charge les PDFs uniquement au montage du composant
    const loadPdfs = async () => {
      console.log('Chargement des PDFs via fetchPdfs...');
      await fetchPdfs(); // fetchPdfs met à jour `allPdfs` dans le contexte
    };
    loadPdfs();
  }, []); // Appelé uniquement une fois

  useEffect(() => {
    // Filtre les PDFs une fois qu'ils sont chargés
    if (allPdfs.length > 0) {
      filterPdfs();
    }
  }, [allPdfs, searchQuery, selectedCategory, selectedDate]); // Réexécute le tri lorsque les critères ou les PDFs changent


  return (
    <div>
      <Navbar />

      {/* Conteneur des champs de filtrage */}
      <div className="mb-4 flex gap-4 pt-4 pl-4 pr-4">
        {/* Champ de recherche */}
        <div className="flex-1">
          <input
            type="text"
            placeholder="Rechercher par titre ou nom de fichier"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
          />
        </div>

        {/* Dropdown pour le filtrage par catégorie */}
        <div className="flex-1">
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
          >
            <option value="">Trier par catégorie</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        {/* Filtre de date unique */}
        <div className="flex-1">
          <input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
            placeholder="Filtrer par date"
          />
        </div>
      </div>

      {/* Liste des PDFs */}
      <div className="px-[30px] lg:px-[4rem]">

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {currentPdfs.map((data, index) => (
      <div
        className={`border border-gray-300 shadow-md hover:shadow-xl rounded-lg transition-transform transform hover:-translate-y-2 bg-white flex flex-col justify-between overflow-hidden group ${data.isOpen ? 'h-auto' : 'h-[380px]'}`}
        key={index}
      >
        {/* Contenu principal de la carte */}
        <div className="p-4 flex flex-col">
          {/* Titre */}
          <h6 className="font-semibold text-lg text-gray-800 truncate mb-2">
            {data.title || 'Titre manquant'}
          </h6>
          <h6 className="text-gray-600">{data.pdf}</h6>

          {/* Affichage de la catégorie */}
          {data.category && (
            <h6 className="text-teal-400">{categories.find(category => category._id === data.category)?.name || 'Aucune catégorie'}</h6>
          )}

          {/* Dates */}
          {data.startDate && data.endDate && (
            <h6 className="text-sm text-gray-500">
              du {new Date(data.startDate).toLocaleDateString()} au {new Date(data.endDate).toLocaleDateString()}
            </h6>
          )}
        </div>

        {/* Footer : Boutons "Voir le PDF" et "Modifier" */}
        <div className="bg-gray-50 p-3 border-t border-gray-200 flex flex-col justify-between mt-auto">
          {/* Bouton Voir le PDF */}
          <button onClick={() => showPdf(data.pdf)} className="bg-teal-400 text-white rounded-md p-2 text-center mb-2">
            Voir le PDF
          </button>

          {/* Bouton Modifier */}
          <button
            onClick={() => toggleDropdown(data._id)}
            className="bg-gray-300 text-black rounded-md p-2"
          >
            {data.isOpen ? 'Fermer' : 'Modifier'}
          </button>
        </div>

        {/* Champs supplémentaires, affichés uniquement si isOpen est true */}
        {data.isOpen && (
          <div className="mt-4 p-4 bg-gray-100 border-t border-gray-300 rounded-b-lg">
            <div className="mb-4">
              <label className="block text-sm font-semibold">Titre</label>
              <input
                type="text"
                value={data.title || ''}
                onChange={(e) => handleChange(e, data._id, 'title')}
                className="w-full border border-gray-300 rounded-md p-2 mt-1"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-semibold">Date de début</label>
              <input
                type="date"
                value={data.startDate ? new Date(data.startDate).toISOString().split('T')[0] : ''}
                onChange={(e) => handleChange(e, data._id, 'startDate')}
                className="w-full border border-gray-300 rounded-md p-2 mt-1"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-semibold">Date de fin</label>
              <input
                type="date"
                value={data.endDate ? new Date(data.endDate).toISOString().split('T')[0] : ''}
                onChange={(e) => handleChange(e, data._id, 'endDate')}
                className="w-full border border-gray-300 rounded-md p-2 mt-1"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-semibold">Catégorie</label>
              <select
                value={data.category || ''}
                onChange={(e) => handleChange(e, data._id, 'category')}
                className="w-full border border-gray-300 rounded-md p-2 mt-1"
              >
                <option value="">Sélectionner une catégorie</option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-semibold">Modifier le fichier PDF</label>
              <input
                type="file"
                onChange={(e) => handleFileChange(e, data._id)}
                className="w-full border border-gray-300 rounded-md p-2 mt-1"
              />
            </div>

            {/* Bouton de mise à jour */}
            <button
              onClick={() => updatePdf(data._id, {
                title: data.title,
                startDate: data.startDate,
                endDate: data.endDate,
                category: data.category,
              }, data.pdfFile)}
              className="w-full bg-green-600 text-white p-2 rounded-md mt-2"
            >
              Mettre à jour
            </button>

            {/* Bouton Supprimer */}
            <button
              onClick={() => deletePdf(data._id)}
              className="w-full bg-red-600 text-white p-2 rounded-md mt-4"
            >
              Supprimer
            </button>
          </div>
        )}
      </div>
    ))}
  </div>
  <Pagination
  currentPage={currentPage}
  itemsPerPage={itemsPerPage}
  totalItems={pdfs.length}
  paginate={setCurrentPage}
  setItemsPerPage={setItemsPerPage}
  />
  </div>


    </div>
  );
}

export default UploadedFiles;
