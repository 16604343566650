import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Signup from './components/authentication/Signup';
import Login from './components/authentication/Login';
import UploadedFiles from './components/UploadedFiles';
import { PdfContextProvider } from './context/PdfContext';
import './App.css';
import FrontPdf from './components/FrontPdf';

// Composant ProtectedRoute pour rediriger vers la page de login si l'utilisateur n'est pas authentifié
const ProtectedRoute = ({ element, ...rest }) => {
  const token = localStorage.getItem('token');
  return token ? element : <Navigate to="/" />;
};

function App() {
  return (
    <PdfContextProvider>
      <Router>
        <div className="App">
          <Routes>
            {/* Routes publiques */}
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            {/* Route dynamique pour afficher les PDFs de l'utilisateur */}
            <Route path="/:userId" element={<FrontPdf />} />
            {/* Routes protégées */}
            <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
            <Route path="/uploadedfiles" element={<ProtectedRoute element={<UploadedFiles />} />} />
          </Routes>
        </div>
      </Router>
    </PdfContextProvider>
  );
}

export default App;