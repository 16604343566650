import React from 'react';
import { Link } from 'react-router-dom';
const backendUrl = `${window.location.protocol}//${window.location.hostname}:9000`; // récupérer dynamiquement l'url backend ( utiliser pour appelé les logos)
// import { usePdfContext } from '../context/PdfContext';

function HeaderPublic({ userInfo }) {
  // const {setAllPdfs} = usePdfContext()
  
  return (
    <header className="text-white py-4 min-h-[150px] flex flex-col sm:flex-row items-center justify-between px-4">
      {/* Logo */}

      <div className="user-info mb-4">
        <h2 className="font-bold text-xl">{userInfo.name}</h2>
        {userInfo.profileImage ? (
        <img 
         src={`${backendUrl}${userInfo.profileImage}`} 
         alt="Logo" 
         className="w-[100px] h-auto" 
        />
        ) : (
        <span>Aucun logo</span> 
      )}
      </div>
      
      {/* Bouton de contact */}
      <div className="flex items-center">
      <button className="bg-teal-500 text-gray-50 border-2 border-teal-500 py-2 px-6 rounded-md text-sm font-semibold hover:bg-teal-400 transition-colors duration-300 ease-in-out">
  Contact
</button>
      </div>
    </header>
  );
}

export default HeaderPublic;