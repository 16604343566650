import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const PdfContext = createContext();

export function usePdfContext() {
  return useContext(PdfContext);
}

export function PdfContextProvider({ children }) {
  const [allPdfs, setAllPdfs] = useState([]);
  const token = localStorage.getItem('token');

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL+'/api',
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
    },
  });

  // Fonction pour récupérer les PDFs
  const fetchPdfs = async () => {
    try {
      console.log('Fetching PDFs...');
      const result = await authAxios.get('/get-files', { params: { all: true } });

      // Vérification de la structure des données
      if (result.data && Array.isArray(result.data.data)) {
        console.log('Fetched PDFs:', result.data.data); // Log pour confirmer
        setAllPdfs(result.data.data); // Mettre à jour avec le bon chemin
      } else {
        console.error('Structure inattendue des données', result.data);
        setAllPdfs([]); // Valeur par défaut en cas de structure inattendue
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des PDFs', error);
      setAllPdfs([]); // Valeur par défaut en cas d'erreur
    }
  };

  // Récupérer les données lors du montage du contexte
  useEffect(() => {
    fetchPdfs();
  }, []);

  return (
    <PdfContext.Provider value={{ allPdfs, setAllPdfs, fetchPdfs }}>
      {children}
    </PdfContext.Provider>
  );
}
