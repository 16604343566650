import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';  // Pour récupérer l'ID utilisateur depuis l'URL
import HeaderPublic from './HeaderPublic';
import Pagination from './Pagination';  // Assurez-vous que le composant Pagination est bien importé
import axios from 'axios';

function FrontPdf() {
  const [pdfs, setPdfs] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const { userId } = useParams();  // Récupérer l'ID utilisateur depuis l'URL
  const [searchQuery, setSearchQuery] = useState('');  // Pour la recherche par titre ou catégorie
  const [selectedCategory, setSelectedCategory] = useState('');  // Pour le filtre par catégorie
  const [selectedDate, setSelectedDate] = useState('');  // Pour le filtre par date
  const [selectedSort, setSelectedSort] = useState('newest');  // Tri par défaut : Les plus récents
  const [filteredPdfs, setFilteredPdfs] = useState([]);  // Pour les PDFs filtrés dynamiquement
  const [loading, setLoading] = useState(true); // Indicateur de chargement
  const [dataLoaded, setDataLoaded] = useState(false); // Pour gérer l'affichage différé du message

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL+"/api", // URL du backend
  });

  // Fonction pour récupérer les informations et les PDFs de l'utilisateur via son ID
  const getUserData = async () => {
    try {
      setLoading(true); // Début du chargement
      const result = await authAxios.get(`/${userId}`);  // Utilise l'ID de l'utilisateur
      setUserInfo(result.data);  // Assigne les données utilisateur
      setPdfs(result.data.pdfFiles);  // Assigne les PDFs associés à cet utilisateur
    } catch (error) {
      console.error('Erreur lors de la récupération des données utilisateur:', error);
    } finally {
      setLoading(false); // Fin du chargement
      setDataLoaded(true); // Indique que la récupération des données est terminée
    }
  };

  useEffect(() => {
    if (userId) {
      getUserData();  // Récupérer les données de l'utilisateur dès que l'ID est disponible
    }
  }, [userId]);

  // Fonction pour afficher le PDF
  const showPdf = (pdfFileLink) => {
    window.open(pdfFileLink, "_blank", "noreferrer");
  };

  // Fonction pour vérifier si le PDF est dans la plage de dates
  const isPdfVisible = (startDate, endDate) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Vérifie si la date actuelle est dans la plage de dates
    return currentDate >= start && currentDate <= end;
  };

  // Fonction pour vérifier si le PDF est visible en fonction de la date sélectionnée
  const isPdfVisibleByDate = (startDate, endDate, selectedDate) => {
    const selected = new Date(selectedDate);
    const start = new Date(startDate);
    const end = new Date(endDate);

    return selected >= start && selected <= end;
  };

  // Fonction pour filtrer les PDFs en fonction des critères de recherche, catégorie, et date
  const filterPdfs = () => {
    let filtered = pdfs;

    // Filtrage par recherche (titre ou catégorie)
    if (searchQuery) {
      filtered = filtered.filter(pdf =>
        pdf.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (pdf.category && pdf.category.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    }

    // Filtrage par catégorie
    if (selectedCategory) {
      filtered = filtered.filter(pdf => pdf.category === selectedCategory);
    }

    // Filtrage par date choisie
    if (selectedDate) {
      filtered = filtered.filter(pdf => isPdfVisibleByDate(pdf.startDate, pdf.endDate, selectedDate));
    }

    // Application du filtrage des dates (uniquement les PDFs dont la date actuelle est entre startDate et endDate)
    filtered = filtered.filter(pdf => isPdfVisible(pdf.startDate, pdf.endDate));

    // Tri des PDFs en fonction du critère sélectionné
    if (selectedSort) {
      if (selectedSort === 'alphabetical') {
        filtered = filtered.sort((a, b) => {
          const titleA = a.title ? a.title.toLowerCase() : "";
          const titleB = b.title ? b.title.toLowerCase() : "";

          // Utilisation d'une extraction des nombres pour trier correctement
          const regex = /(\D*)(\d*)/; // Capture les parties non numériques et numériques
          const [, nonNumA, numA] = titleA.match(regex);
          const [, nonNumB, numB] = titleB.match(regex);

          // Comparer d'abord les parties non numériques
          const nonNumComparison = nonNumA.localeCompare(nonNumB);
          if (nonNumComparison !== 0) {
            return nonNumComparison;
          }

          // Comparer les parties numériques (en tant que nombres)
          return (parseInt(numA, 10) || 0) - (parseInt(numB, 10) || 0);
        });  // Tri alphabétique par titre
      } else if (selectedSort === 'oldest') {
        filtered = filtered.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));  // Les plus anciens
      } else if (selectedSort === 'newest') {
        filtered = filtered.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));  // Les plus récents
      }
    }
    setFilteredPdfs(filtered);
  };

  useEffect(() => {
    filterPdfs();  // Refiltrer chaque fois que la recherche, la catégorie, la date, ou les PDFs changent
  }, [searchQuery, selectedCategory, selectedDate, selectedSort, pdfs]);

  // Extraction des catégories
  const categories = [...new Set(pdfs.map(pdf => pdf.category))];

  return (
    <div>
      <HeaderPublic userInfo={userInfo} />  {/* appel du composant header */}

      {/* Section de champs de filtrage */}
      <div className="filters mb-8 w-full px-4 flex flex-wrap gap-2 justify-between">
        {/* Recherche */}
        <input
          type="text"
          placeholder="Rechercher un PDF..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="p-2 border border-gray-300 rounded-md flex-grow sm:w-1/4 lg:w-1/5"
        />

        {/* Sélection de catégorie */}
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="p-2 border border-gray-300 rounded-md flex-grow sm:w-1/4 lg:w-1/5"
        >
          <option value="">Toutes les catégories</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>

        {/* DatePicker pour filtrer par date */}
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="p-2 border border-gray-300 rounded-md flex-grow sm:w-1/4 lg:w-1/5"
        />

        {/* Sélection du tri */}
        <select
          value={selectedSort}
          onChange={(e) => setSelectedSort(e.target.value)}
          className="p-2 border border-gray-300 rounded-md flex-grow sm:w-1/4 lg:w-1/5"
        >
          <option value="newest">Les plus récents</option>
          <option value="oldest">Les plus anciens</option>
          <option value="alphabetical">Ordre alphabétique</option>
        </select>
      </div>

      {/* Carte PDF */}
      <div className="px-[30px] lg:px-[4rem]"> {/* Ajoute un padding de 30px sur mobile/tablette et un padding de 4rem sur grands écrans */}
        {loading ? (
          <p>Chargement des fichiers PDF...</p>
        ) : filteredPdfs && filteredPdfs.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {filteredPdfs.map((data, index) => (
              <a
                href={data.pdf || '#'}
                target="_blank"
                rel="noopener noreferrer"
                className="border border-gray-300 shadow-md hover:shadow-xl rounded-lg transition-transform transform hover:-translate-y-2 bg-white flex flex-col justify-between overflow-hidden group"
                key={index}
                style={{ height: '380px' }} // Hauteur fixe pour chaque carte
              >
                {/* Contenu principal */}
                <div className="p-4">
                  {/* Titre */}
                  <h3 className="font-semibold text-2xl text-gray-800 truncate mb-2">
                    {data.title || 'Titre manquant'}
                  </h3>
                  {/* Catégorie */}
                  <p className="text-lg text-teal-400 mb-2">
                    {data.category || 'Catégorie manquante'}
                  </p>
                  {/* Dates */}
                  {data.startDate && data.endDate ? (
                    <p className="text-sm text-gray-500">
                      Du {new Date(data.startDate).toLocaleDateString()} au {new Date(data.endDate).toLocaleDateString()}
                    </p>
                  ) : (
                    <p className="text-sm text-gray-500">Dates de période manquantes</p>
                  )}
                </div>
                {/* Footer card : Bouton "Consulter" */}
                <div className="bg-gray-50 p-3 border-t border-gray-200 flex justify-center group-hover:bg-teal-400 transition-colors">
                  <button
                    className="text-teal-400 font-semibold text-sm group-hover:text-white transition-colors uppercase"
                  >
                    Consulter
                  </button>
                </div>
              </a>
            ))}
          </div>
        ) : dataLoaded ? (
          <p>Aucun fichier PDF trouvé.</p>
        ) : null}
      </div>
    </div>
  );
}

export default FrontPdf;
