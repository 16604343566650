import {useEffect, useState} from "react";
import { useNavigate, Link } from "react-router-dom";

function Signup() {
  const navigate = useNavigate();

  // State variables to store user input
  //const [name, setName] = useState("");
  //const [email, setEmail] = useState("");
  //const [password, setPassword] = useState("");
  const [mairieOptions, setMairieOptions] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    mairieId: 0,
  });

  const fetchMairieOptions = async () => {
    try {
      const response = await fetch("https://mymairie.fr/backoffice/apis/villes/",{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      });
      const data = await response.json();
      let dataArray = [];
      data.map((ville) => {
        dataArray.push({
          id: ville.mairie_id,
          value: ville.ville,
        })
      })
      setMairieOptions(dataArray);
    } catch (error) {
      console.error("Error fetching mymairie options:", error);
    }
  };

  useEffect(() => {
    fetchMairieOptions();
  }, []);
  // Function to handle user registration
  async function registerUser(event) {
    event.preventDefault();

    // Send a POST request to the server for user registration
    const response = await fetch(process.env.REACT_APP_API_URL+"/api/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    // Parse the response data
    const data = await response.json();

    if (data.status === "ok") {
      // If registration is successful, navigate to the login page
      navigate("/");
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target)
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  return (
    <div className="min-h-screen flex items-center justify-center bg-blue-400">
      <div className="max-w-md w-full p-8 bg-white shadow-lg rounded-md">
        <h1 className="text-3xl text-center font-semibold mb-4">Inscription</h1>

        <form onSubmit={registerUser}>
          <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Pseudo"
              className="w-full p-2 mb-4 border rounded-md"
          />
          <select
              name="mairieId"
              value={formData.mairieId}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded-md flex-grow"
          >
            <option value="">Sélectionnez mairie</option>
            {mairieOptions.length > 0
                ? mairieOptions.map((mairie) => (
                    <option key={mairie.value} value={mairie.id}>
                      {mairie.value}
                    </option>
                ))
                : "loading Mairie.."}
          </select>
          <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className="w-full p-2 mb-4 border rounded-md"
          />
          <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
              className="w-full p-2 mb-4 border rounded-md"
          />

          <button
              type="submit"
              className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
          >
            M'inscrire
          </button>
        </form>

        <div className="mt-8 text-center">
          <p className="mb-2">OU</p>
          <Link to="/" className="text-blue-500 hover:underline">
            Me connecter
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Signup;
